/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getAmigoBirthday, getBirthdaysList } from '../../redux/actions';
import { BirthdayFeed } from "./styles";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { findMonth } from '../../utils/methods';

class BirthdayWishCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalBirthdayCount: 0,
    }
  }

  componentDidMount(){
    const { getBirthdaysList, getAmigoBirthday } = this.props;
    getBirthdaysList();

    // const { getAmigoBirthday, getWorkAnniversary } = this.props;
    getAmigoBirthday();

    // window.console.log("amigoBirthdayList", this.props.amigoBirthdayList)
  }

  componentDidUpdate(prevProps) {
    if (this.props.amigoBirthdayList !== prevProps.amigoBirthdayList) {
     
      const { amigoBirthdayList } = this.props;
      // window.console.log("amigoBirthdayList",amigoBirthdayList)
      this.setState({totalBirthdayCount:amigoBirthdayList.recent_birthday.length + amigoBirthdayList.today_birthday.length + amigoBirthdayList.upcoming_birthday.length})
      // this.anniversaryObject = [
      //   ...this.props.birthdayList.recent_anniversary,
      //   ...this.props.birthdayList.today_anniversary,
      //   ...this.props.birthdayList.upcoming_anniversary,
      // ];
    }
  }

  render() {
    const { callback } = this.props;
    const date = new Date();
    const month = findMonth(date.getMonth());
    return (
      <BirthdayFeed birthday>
        <div className="firstContainer">
          <div className="count">
            {this.state.totalBirthdayCount}+
          </div>
          <div className="birthday">{this.props.t("People’s Birthdays")} <br/>{this.props.t("In")} {this.props.t(month)}</div>
        </div>
        <div className="bottomWrapper">
          <div className="button" onClick={() => callback()}>{this.props.t("Wish Them!")}</div>
        </div>
      </BirthdayFeed>
    );
  }
}

BirthdayWishCard.propTypes = {
  birthdayList: PropTypes.array,
  getBirthdaysList: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  callback: PropTypes.func,
  amigoBirthdayList:PropTypes.object,
  getAmigoBirthday:PropTypes.func,
};
const mapStateToProps = (state) => ({
  birthdayList: state.profileData.birthdayList,
  amigoBirthdayList: state.social.birthdayList,
})
const mapDispatchToProps = (dispatch) => ({
  getBirthdaysList: () => dispatch(getBirthdaysList()),
  getAmigoBirthday: () => dispatch(getAmigoBirthday()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BirthdayWishCard));
