/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyledModal, StyledHeader, StyledBody, CloseIcon, HeaderText } from '../PhotoAndPostFlow/styles';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import {RecognitonNameWrapper, RecognitonDescription} from './styles';

class RecognitionDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
    };
  }

  render() {
    const { showModal, onClose, t, recognitionData } = this.props;
    return (
      <div>
        <StyledModal show={showModal} onHide={onClose} width="550px">
          <StyledHeader background border>
            { <HeaderText color>{t('About Core Value')}</HeaderText>}
            <CloseIcon onClick={onClose}>
              <img src={ImageUrl+"/social-feed/close.png"} width="22px" height="22px"></img>
            </CloseIcon>
          </StyledHeader>
          <StyledBody>
            <RecognitonNameWrapper>
              <div className='icon'>
                <img src={ImageUrl+"/"+recognitionData.image} width="22px" height="22px"></img>
              </div>
              <div className='nameWrapper'>
                <div className='name'>{this.props.t("Core Value Name")}</div>
                <div className='title'>{this.props.t(recognitionData.core_value)}</div>
              </div>
            </RecognitonNameWrapper>
            <RecognitonDescription>
              <div className='title'>{this.props.t("Description")}</div>
              {recognitionData.description?<div className='description'>{recognitionData.description}</div>:
                <div className='noData'>{this.props.t("No Description Added  for this core value")}</div>
              }
            </RecognitonDescription>
          </StyledBody>
        </StyledModal>
      </div>
    )
  }
}

RecognitionDescription.propTypes = {
  history: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func,
  recognitionData: PropTypes.array
};

export default connect(null, null)(withTranslation()(RecognitionDescription));
