/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getWorkAnniversary } from '../../redux/actions';
import { BirthdayFeed } from "./styles";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { findMonth } from '../../utils/methods';

class AnniversaryWishCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAnniversaryCount: 0,
    }
  }
  anniversaryObject = {};
  componentDidMount(){
    const { getWorkAnniversary } = this.props;
    getWorkAnniversary();

    // this.setState({totalAnniversaryCount:birthdayList.recent_anniversary.length + birthdayList.today_anniversary.length + birthdayList.upcoming_anniversary.length})
    // window.console.log("birthdayList",this.props.birthdayList)
  }

  componentDidUpdate(prevProps) {
    if (this.props.birthdayList !== prevProps.birthdayList) {
     
      const { birthdayList } = this.props;
      // window.console.log("birthdayList",birthdayList)
      this.setState({totalAnniversaryCount:birthdayList.recent_anniversary.length + birthdayList.today_anniversary.length + birthdayList.upcoming_anniversary.length})
      // this.anniversaryObject = [
      //   ...this.props.birthdayList.recent_anniversary,
      //   ...this.props.birthdayList.today_anniversary,
      //   ...this.props.birthdayList.upcoming_anniversary,
      // ];
    }
  }

  render() {
    // const { birthdayList } =this.props;
    const date = new Date();
    const month = findMonth(date.getMonth());
    // const totalAnniversaryCount = birthdayList.recent_anniversary.length + birthdayList.today_anniversary.length + birthdayList.upcoming_anniversary.length;
    return (
      <BirthdayFeed >
        <div className="firstContainer">
          <div className="count">
            {this.state.totalAnniversaryCount}+
          </div>
          <div className='birthday'>{this.props.t("Work Anniversaries")} <br/>In {month}</div>
        </div>
        <div className='bottomWrapper'>
          <div className='button' onClick={() => this.props.callback()}>{this.props.t("Shout Out!")}</div>
        </div>
      </BirthdayFeed>
    );
  }
}

AnniversaryWishCard.propTypes = {
  birthdayList: PropTypes.array,
  getWorkAnniversary: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  callback: PropTypes.func
};
const mapStateToProps = (state) => ({
  birthdayList: state.profileData.workAnniversaryList
})
const mapDispatchToProps = (dispatch) => ({
  getWorkAnniversary: () => dispatch(getWorkAnniversary())
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AnniversaryWishCard));
